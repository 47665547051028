<template>
    <div class="service">
        <div class="service-body">
            <img class="service-qrcode" :src="info.qrcode">
            <div class="service-list">
                <div class="service-list-title">{{$t('邮箱地址')}}</div>
                <div class="service-list-brief">{{info.email?info.email:'-'}}</div>
            </div>
            <div class="service-list">
                <div class="service-list-title">{{$t('Wechat/Zalo')}}</div>
                <div class="service-list-brief"><a :href="info.zalo_url"
                                                   style="color:#888">{{info.zalo?info.zalo:'-'}}</a></div>
            </div>
            <div class="service-list">
                <div class="service-list-title">{{$t('Wechat/Zalo')}}</div>
                <div class="service-list-brief">{{info.wechat?info.wechat:'-'}}</div>
            </div>
            <div class="service-list">
                <div class="service-list-title">{{$t('contact_tel')}}</div>
                <div class="service-list-brief"><a :href="'tel:'+info.service_tel" style="color:#888">{{info.service_tel?info.service_tel:'-'}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Service",
        data() {
            return {
                info: '' // 客户服务信息
            };
        },
        mounted() {
            this.siteConfig()
        },
        methods: {
            siteConfig() {
                // 获取客户服务信息
                this.$http.post("/v1/siteConfig").then((res) => {
                    if (res.code == 200) {
                        this.info = res.data
                    }
                });
            },
        },
    };
</script>

<style scoped>
    .service {
    }

    .service-body {
        padding: 12px 16px;
        border-radius: 5px;
        box-shadow: 0 0 15px 0 #ccc;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }

    .service-qrcode {
        width: 240px;
        height: 240px;
    }

    .service-list {
        /* display: flex; */
        /* align-items: center; */
        border-bottom: 1px solid #f5f5f5;
        padding: 8px 0;
    }

    .service-list-title {
        color: #333;
    }

    .service-list-brief {
        margin-top: 5px;
        width: 240px;
        word-wrap: break-word;
        white-space: pre-wrap;
        color: #888;
    }
</style>